import React from 'react';
import classes from './invoiceLink.css';
import { object, bool } from 'prop-types';
import store from 'src/store';
import Link from 'src/components/Link';
import ExternalLink from 'feather/external-link';
import safeAccessInvoice from 'src/util/safeAccessInvoice';

const linkIconSize = 15;

function InvoiceLink(props) {
    // originalInvoice used for detailItems, header required for Invoices Table
    const { row, header, originalInvoice = false } = props;
    const { invoiceLetterMap } = store.getState().app;

    // Use 'a' instead of invoiceNumber on Invoice page
    let invoiceNumber = safeAccessInvoice(row, 'invoiceNumber');
    let orderNumber = safeAccessInvoice(row, 'orderNumber');
    const originalInvoiceNumber = safeAccessInvoice(
        row,
        'originalInvoiceNumber'
    );
    const originalOrderNumber = safeAccessInvoice(row, 'originalOrderNumber');

    if (
        originalInvoice ||
        header.value === invoiceLetterMap.OriginalInvoiceNumber
    ) {
        invoiceNumber = originalInvoiceNumber;
        orderNumber = originalOrderNumber;
    }
    //Add formatting for null return in table row!
    return invoiceNumber ? (
        <>
            <span className={classes.linkText}>{invoiceNumber}</span>
            {orderNumber ? (
                <Link
                    href={`/invoices/invoice/?id=${invoiceNumber}&orderNumber=${orderNumber}`}
                >
                    <span className={classes.linkIcon}>
                        <ExternalLink
                            className={classes.invoiceIcon}
                            size={linkIconSize}
                        />
                    </span>
                </Link>
            ) : null}
        </>
    ) : (
        'N/A'
    );
}

export default InvoiceLink;

InvoiceLink.propTypes = {
    row: object,
    originalInvoice: bool,
    header: object
};
