import React from 'react';
import classes from './payoutDetail.css';
import { overrideHeaders } from './headers';
import PageHeader from 'src/components/PageHeader';
import TableWrapper from 'src/components/TableWrapper';
import { TableController } from 'fronds/Components';
import { any, arrayOf, shape, string, number, instanceOf } from 'prop-types';
import QueryParams from 'src/util/urlParams/queryParams';
import PaginationWrapper from 'src/components/PaginationWrapper';
import { resultsPerPageDropdownOptions } from 'src/constants/resultsPerPageOptions';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';
import Splash from 'src/components/Splash';
import NoRules from 'src/icons/norules.svg';
import TableExport from 'src/components/TableExport';

function PayoutDetail(props) {
    const {
        commissionPayout,
        commissionOverride,
        queryParams,
        headers,
        userId,
        branch
    } = props;

    // If we can't find a name, the userId (email) will do
    const beneficiaryName = commissionPayout.rows.length
        ? commissionPayout.rows[0].beneficiaryName
        : userId;

    function handleSort(sort, asc) {
        queryParams
            .setSortParams(sort, asc)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleColumnSearch(search, header) {
        queryParams
            .setColumnSearchParam(header, search, headers)
            .setColumnComparatorParams(header, headers)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleResultsPerPageChange(resultsPerPage) {
        queryParams
            .setResultsPerPageParam(resultsPerPage.value)
            .setPaginationParams(1)
            .setURLParams();
    }

    const headerProps = {
        handleSort,
        initialSort: queryParams.sort,
        handleColumnSearch,
        initialColumnSearch: queryParams.getColumnSearch(headers)
    };

    const commissionsTableFallback = (
        <Splash
            isCompact={false}
            image={<NoRules />}
            message={'No payouts to show'}
        />
    );

    return (
        <>
            <PageHeader text={`Payout Detail - ${beneficiaryName}`} />
            {branch ? (
                <div className={classes.subHeader}>Branch - {branch}</div>
            ) : null}
            <TableController
                right={
                    <>
                        <DropdownSelectWrapper
                            label={'Page Size'}
                            inheritedClass={'mini'}
                            listItems={resultsPerPageDropdownOptions}
                            selectedValues={
                                resultsPerPageDropdownOptions.find(
                                    opt =>
                                        opt.value === queryParams.resultsPerPage
                                ) || resultsPerPageDropdownOptions[0]
                            }
                            onSelectionChange={handleResultsPerPageChange}
                        />
                        <PaginationWrapper
                            totalRows={
                                commissionPayout.filteredRows
                                    ? commissionPayout.filteredRows.length
                                    : 0
                            }
                            handlePagination={page => {
                                queryParams
                                    .setPaginationParams(page)
                                    .setURLParams();
                            }}
                            currentPage={queryParams.pageNumber}
                            pageSize={queryParams.resultsPerPage}
                        />
                    </>
                }
            />
            <TableWrapper
                title={'Commission from Rules'}
                rows={commissionPayout.getPage(queryParams.pageNumber)}
                headers={headers}
                headerProps={headerProps}
                fallback={commissionsTableFallback}
                primaryKey={'invoiceNumber'}
            />
            <div className={classes.tableFooter}>
                <TableExport
                    rows={commissionPayout.filteredRows}
                    headers={headers}
                />
            </div>
            <TableWrapper
                title={'Commission from Overrides'}
                className={classes.overrideTable}
                rows={commissionOverride}
                headers={overrideHeaders}
                headerProps={headerProps}
                fallback={commissionsTableFallback}
                primaryKey={false}
            />
            <div className={classes.tableFooter}>
                <TableExport
                    rows={commissionOverride}
                    headers={overrideHeaders}
                />
            </div>
        </>
    );
}

PayoutDetail.propTypes = {
    commissionPayout: any,
    commissionOverride: any,
    queryParams: instanceOf(QueryParams),
    headers: any,
    userId: string,
    branch: string,
    data: arrayOf(
        shape({
            id: number.isRequired,
            commissionRuleTreeId: number.isRequired,
            commissionRuleNodeId: number.isRequired,
            commissionPayoutRecalculationRequestId: number.isRequired,
            payoutGeneratedDateUtc: string.isRequired,
            payPeriodId: number.isRequired,
            invoiceNumber: number.isRequired,
            invoiceLineNumber: number.isRequired,
            beneficiaryType: number.isRequired,
            beneficiaryUserId: string.isRequired,
            branch: string.isRequired,
            payAmount: number.isRequired,
            payCurrency: string.isRequired
        })
    )
};

export default PayoutDetail;
