import { currencyFormat } from 'src/util/numberFormats';
import allSearchOptions from 'src/constants/allSearchOptions';
import InvoiceLink from 'src/components/InvoiceLink';
import ProjectLink from 'src/components/ProjectLink';
import { dateFormat } from 'src/util/numberFormats';
import { AbsoluteLayout } from 'fronds/Components';

export default [
    {
        value: 'invoiceNumber',
        label: 'Invoice Number',
        primaryKey: true,
        component: InvoiceLink
    },
    {
        value: 'projectNumber',
        label: 'Project Number',
        component: ProjectLink
    },
    { value: 'description', label: 'Rule Description' },
    {
        value: 'commissionRuleNodeDescription',
        label: 'Payout Type Description'
    },
    {
        value: 'payAmount',
        label: 'Commission Amount',
        format: currencyFormat,
        searchOptions: allSearchOptions,
        selectedSearchOption: allSearchOptions[1]
    }
];

export const overrideHeaders = [
    {
        value: 'salespersonName',
        label: 'Beneficiary',
        searchable: false,
        sortable: false
    },
    {
        value: 'invoiceNumber',
        label: 'Inv No',
        component: InvoiceLink,
        searchable: false,
        sortable: false
    },
    {
        value: 'requestedByUserId',
        label: 'Requester Email',
        searchable: false,
        sortable: false
    },
    {
        component: AbsoluteLayout,
        value: 'requestedAmount',
        label: 'Requested Amount',
        format: currencyFormat,
        searchable: false,
        sortable: false
    },
    {
        component: AbsoluteLayout,
        value: 'dispositionAmount',
        label: 'Granted Amount',
        format: currencyFormat,
        searchable: false,
        sortable: false
    },
    {
        value: 'requestTimeUtcUnix',
        label: 'Created On',
        format: dateFormat,
        primaryKey: true,
        searchable: false,
        sortable: false
    }
];
