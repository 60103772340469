import React from 'react';
import classes from './projectLink.css';
import { object } from 'prop-types';
import Link from 'src/components/Link';
import ExternalLink from 'feather/external-link';

const linkIconSize = 15;

function ProjectLink(props) {
    const { row } = props;

    const projectNumber = row.projectNumber;

    return projectNumber ? (
        <>
            <span className={classes.linkText}>{projectNumber}</span>
            <Link href={`/projects/project/?id=${projectNumber}`}>
                <span className={classes.linkIcon}>
                    <ExternalLink
                        className={classes.invoiceIcon}
                        size={linkIconSize}
                    />
                </span>
            </Link>
        </>
    ) : (
        'N/A'
    );
}

export default ProjectLink;

ProjectLink.propTypes = {
    row: object,
    header: object
};
